import './src/styles/style.css';

import React from 'react';
import { Auth0Provider } from '@auth0/auth0-react';
import { navigate } from 'gatsby';

const onRedirectCallback = (appState) => navigate(appState?.returnTo || '/');

export const wrapRootElement = ({ element }) => {
    const origin = typeof window !== "undefined" ? window.location.origin : ""

    return (
        <Auth0Provider
            domain={process.env.GATSBY_AUTH0_DOMAIN || ""}
            clientId={process.env.GATSBY_AUTH0_CLIENTID || ""}
            authorizationParams={{
                redirect_uri: origin
            }}
            onRedirectCallback={onRedirectCallback}
            cacheLocation="localstorage"
        >
            {element}
        </Auth0Provider>
    );
};