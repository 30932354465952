module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["en","ja"],"defaultLanguage":"en","siteUrl":"https://realis-simulation.com/","trailingSlash":"always","i18nextOptions":{"fallbackLng":"en","supportedLngs":["en","ja"],"defaultNS":"common","interpolation":{"escapeValue":false}},"pages":[{"matchPath":"/:lang?/insights/blogs/:uid","getLanguageFromPath":true,"excludeLanguages":["ja"]},{"matchPath":"/:lang?/insights/brochures/:uid","getLanguageFromPath":true,"excludeLanguages":["ja"]},{"matchPath":"/:lang?/insights/conferences/:uid","getLanguageFromPath":true,"excludeLanguages":["ja"]},{"matchPath":"/:lang?/insights/press-releases/:uid","getLanguageFromPath":true,"excludeLanguages":["ja"]},{"matchPath":"/:lang?/insights/technical-papers/:uid","getLanguageFromPath":true,"excludeLanguages":["ja"]},{"matchPath":"/:lang?/insights/webinars/:uid","getLanguageFromPath":true,"excludeLanguages":["ja"]},{"matchPath":"/:lang?/insights/white-papers/:uid","getLanguageFromPath":true,"excludeLanguages":["ja"]},{"matchPath":"/:lang?/about-us/meet-the-team/:uid","getLanguageFromPath":true,"excludeLanguages":["ja"]}]},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":600,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","quality":50,"withWebp":false,"withAvif":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-breadcrumb/gatsby-browser.js'),
      options: {"plugins":[],"useAutoGen":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Realis Simulation","short_name":"Realis","start_url":"/","background_color":"#0277bd","theme_color":"#0277bd","display":"standalone","icon":"src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"87fc52fda219c0f95885236f4c8bd557"},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"typekit":{}},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://realis-simulation.com/","stripQueryString":true},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
